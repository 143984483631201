import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react"
import axios from "axios"

const UserContext = React.createContext();

function UserProvider({ children }) {
    const [authorizedProducts, setAuthorizedProducts] = useState([]);
    const [accessToken, setAccessToken] = useState("");
    const [busy, setBusy] = useState(false);
    const { getAccessTokenSilently, user, isLoading: authLoading, isAuthenticated } = useAuth0();

    useEffect(() => {
        const getUserProducts = async () => {
            if (process.env.DEBUG_MODE) {
                console.log("Fetching authorized product info from Auth0");
            }
            const access_token = await getAccessTokenSilently();
            setAccessToken(access_token);
            await axios
                .request({
                    url: "/api/get-authorized-products",
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${access_token}`,
                    },
                    params: { userId: user.sub },
                })
                .then(response => {
                    if (process.env.DEBUG_MODE) {
                        console.log("Authorized products:", response?.data);
                    }
                    setAuthorizedProducts(response.data);
                })
                .catch(err => {
                    if (process.env.DEBUG_MODE) {
                        console.log("Fetch data from '/api/get-authorized-products' failed!");
                    }
                    console.log(err.message);
                });
            setBusy(false);
        };
        if (accessToken === "" && isAuthenticated) {
            setBusy(true);
            getUserProducts();
        }
    }, [user, authLoading, isAuthenticated]);

    const value = { user, isAuthenticated, authorizedProducts, accessToken, loadingUserData: busy || authLoading };
    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
}

function useUserData() {
    return React.useContext(UserContext);
}

export { UserProvider, useUserData };
