import React from "react";
import { navigate } from "gatsby";
import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "./src/services/user-context";
import {getUrlOrigin} from "./src/services/utils";

const onRedirectCallback = appState => {
    console.log("appState?.returnTo: ", appState?.returnTo);
    navigate(appState?.returnTo || "/", { replace: true });
};

export const wrapRootElement = ({ element }) => {
    console.log("Origin: ", getUrlOrigin());
    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
            audience={process.env.GATSBY_AUTH0_AUDIENCE}
            redirectUri={getUrlOrigin()}
            // onRedirectCallback={onRedirectCallback}
        >
            <UserProvider>{element}</UserProvider>
        </Auth0Provider>
    );
};
